import React, { useRef, useLayoutEffect } from 'react';
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import { NavLink } from 'react-router-dom'

const Layout = ({ children }) => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })

    const appRef = useRef(null)

    const toggleMobilemenu = () => {
      if (appRef.current) {
        appRef.current.classList.toggle('show-nav')
      }
    }
  
    const clickMobileNavLink = () => {
      toggleMobilemenu()
    }

    return (
        <div className="wrapper" ref={appRef}>

            <div className="page-navigation-overlay" onClick={toggleMobilemenu}></div>
            <div className="page-navigation">
                <div className="page-navigation-header">
                    <div className="menuTitle">Navigation</div>
                    <div className="close" onClick={toggleMobilemenu}>
                        <span className="line"></span>
                        <span className="line"></span>
                    </div>
                </div>
                
                <nav className="navbar mobile">
                    <ul className="mobilemenu">
                        <li onClick={clickMobileNavLink} className="">
                            <NavLink exact to={'/'} className="nav-link" activeClassName="active">Startseite</NavLink>
                        </li>
                        <li onClick={clickMobileNavLink} className="">
                            <NavLink exact to={'/6-tage'} className="nav-link" activeClassName="active">6-Tage-Wetter</NavLink>
                        </li>
                        <li onClick={clickMobileNavLink} className="">
                            <NavLink exact to={'/14-tage'} className="nav-link" activeClassName="active">14-Tage-Trend</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>

            <Header app={appRef} />
                {children}
            <Footer />
        </div>
    );
}

export default Layout;