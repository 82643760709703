import React, { useEffect, useState, useRef } from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import LoadSpinner from '../LoadSpinner'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

// services
import { weatherInfo, weatherSektoren } from '../../services'

const WeatherHourly = (props) => {

    const weatherData = props.weatherData
    const wogid = props.wogid

    const slickSlider = useRef()

    const windToKmh = (ms) => {
        return (ms * 3.6).toFixed(0);
    }

    const [activeDayIndex, setActiveDayIndex] = useState(0)
    const itemsRef = useRef([])

    // windowsDimension
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width, innerHeight: height } = window
            setWindowDimensions({ width, height })
        }
    
        window.addEventListener('resize', handleResize)
    
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const renderResponsiveDate = (weekday, date) => {
        if(windowDimensions.width > 576) {
            return (
                <div className="dateContainer"><div className="day-name">{weekday},</div><Moment format="DD.MM">{date}</Moment></div>
            )
        } else {
            return (
                <div className="dateContainer"><div className="day-name">{weekday.substring(0,2)},</div><Moment format="DD.MM">{date}</Moment></div>
            )
        }
    }

    const handleClick = (index) => {
        setActiveDayIndex(index)

        if(windowDimensions.width <= 991) {
            itemsRef.current[index].children[1].classList.toggle('showDetails')
        }
    }

    const renderFullDayDetails = (day) => {
        return (
            <div className="row no-gutters fullDay">
                <div className="col-12"><h3>Ganztags für {day.weekday}, den <Moment format="DD.MM.YYYY">{day.date}</Moment></h3></div>
                <div className="divider-v1"></div>
                
                <div className="col-md-4 col-sm-12">
                    <div className="item"><span className="title">Sonnenstunden:</span><span className="value">{day.sd_h} Std.</span></div>
                    <div className="item"><span className="title">Sonnenaufgang:</span><span className="value">{day.sr} Uhr</span></div>
                    <div className="item"><span className="title">Sonnenuntergang:</span><span className="value">{day.ss} Uhr</span></div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="item"><span className="title">Relative Feuchte:</span><span className="value">{day.rh} %</span></div>
                    {/* <div className="item"><span className="title">Niederschlagsstunden:</span><span className="value">{day.pd_h} Std.</span></div> */}
                    <div className="item"><span className="title">Bodendruck:</span><span className="value">{day.pp_hpa} hPa</span></div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="item"><span className="title">Minimum der Erdbodentemperatur (5 cm über Erdboden):</span><span className="value">{day.tg_C} °C</span></div>
                </div>
            </div>
        )
    }

    const renderMobileWeatherCards = (periods) => {
        return periods.map(function (period, index) {
            return(
                <div className="weatherPeriod" key={index}>
                    <div className="item">
                        <div className="periodname">
                            {period.periodname}
                        </div>
                    </div>
                    <div className="item">
                        <div className="weatherIcon">
                            <img title={weatherInfo[period.wm]} alt={weatherInfo[period.wm]} src={require('../../images/icons/' + period.wm + '.svg')} />
                        </div>
                    </div>
                    <div className="item pointValue">
                        {period.tt_C}°
                    </div>
                    <div className="item pointValue">
                        {period.tta_C}°
                    </div>
                    <div className="item">
                        <div className="windSymbols">
                            <div className={'descIcon dd-deg dd-deg--' + period.dd_deg}>
                                <i className="fas fa-location-arrow"></i>
                            </div>
                            <div className="descText">{period.dd_dir}</div>
                        </div>
                    </div>
                    <div className="item pointValue">
                        <div>{windToKmh(period.ff_ms)}</div>
                    </div>
                    <div className="item pointValue">
                        <div>{period.rr_mm}</div>
                    </div>
                    <div className="item pointValue">
                        <div>{period.pop}%</div>
                    </div>
                </div>
            )
        })
    }

    const renderWeatherCards = () => {
        return weatherData[wogid].data.map(function (day, index) {
            return(
                <div className={activeDayIndex === index ? "col-lg-4 activeIndex" : "col-lg-4"} key={index} ref={ref => itemsRef.current[index] = ref}>
                    <div className="weatherCard" onClick={() => handleClick(index)}>
                        <div className="row no-gutters">
                            <div className="col-3 col-md-2 col-lg-12 d-flex jc-c ai-c">
                                <div className="date">
                                    {index === 0 ? ( <div className="dateContainer"><div className="day-name">Heute,</div> <Moment format="DD.MM">{day.date}</Moment></div> ) : (renderResponsiveDate(day.weekday, day.date))}
                                </div>
                            </div>

                            <div className="col-2 col-md-2 col-lg-6 d-flex jc-c ai-c">
                                <div className="weatherIcon">
                                    <img title={weatherInfo[day.wm]} alt={weatherInfo[day.wm]} src={require('../../images/icons/' + day.wm + '.svg')} />
                                </div>
                            </div>

                            <div className="col-md-8 col-lg-6 d-flex jc-c ai-c weatherInfo">
                                <div>
                                    {weatherInfo[day.wm]}
                                </div>
                            </div>

                            <div className="col-2 col-md-2 col-lg-4 d-flex jc-c ai-c">
                                <div className="weatherDetailBox first">
                                    <div className="descIcon"><i className="fad fa-thermometer-half"></i></div>
                                    <div className="value">
                                        <div>{day.tn_C} °C</div>
                                        <div>{day.tx_C} °C</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2 col-md-2 col-lg-4 d-flex jc-c ai-c">
                                <div className="weatherDetailBox">
                                    <div className="descIcon"><i className="fad fa-dewpoint"></i></div>
                                    <div className="value">
                                        <div>{day.pop} %</div>
                                        <div>{day.rr_mm} mm</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2 col-md-2 col-lg-4 d-flex jc-c ai-c">
                                <div className="weatherDetailBox">
                                    <div className="descIcon"><div className={'descIcon dd-deg dd-deg--' + day.dd_deg}><i className="fas fa-location-arrow"></i></div></div>
                                    <div className="value">
                                        <div>{windToKmh(day.ff_ms)} km/h</div>
                                        <div className="uppercase">{day.dd_dir}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {windowDimensions.width <= 991 ?
                        <div className={"mobileWeatherCards"}>
                            <div className="weatherPeriodHeader" key={index}>
                                <div className="item">
                                    <i className="fas fa-clock"></i>
                                </div>
                                <div className="item weather">

                                </div>
                                <div className="item pointValue">
                                    <i className="fas fa-thermometer-three-quarters"></i>
                                </div>
                                <div className="item pointValue">
                                    <i className="fas fa-hand-paper"></i>
                                </div>
                                <div className="item">
                                    <div className="wind">
                                        <i className="fas fa-windsock"></i>
                                    </div>
                                </div>
                                <div className="item pointValue">
                                    <i className="fas fa-wind"></i>
                                </div>
                                <div className="item pointValue">
                                    <i className="fas fa-tint"></i>
                                </div>
                                <div className="item pointValue">
                                    <i className="fas fa-humidity"></i>
                                </div>
                            </div>

                            {renderMobileWeatherCards(day.periods)}

                            {renderFullDayDetails(day)}
                        </div>
                    : null }

                </div>
            )
        })
    }

    const slickNext = () => {
        if(slickSlider.current !== undefined) {
            slickSlider.current.slickNext()
        }
    }

    const slickPrev = () => {
        if(slickSlider.current !== undefined) {
            slickSlider.current.slickPrev()
        }
    }

    const setTimeFromNow = () => {
        return moment(new Date()).format("H")
    }

    /* useEffect(() => {
        if(slickSlider.current !== undefined) {
            if(activeDayIndex === 0) {
                slickSlider.current.slickGoTo(setTimeFromNow())
            }
        }
    }, [slickSlider.current]) */

    /* useEffect(() => {
        if(slickSlider.current !== undefined) {
            if(activeDayIndex === 0) {
                slickSlider.current.slickGoTo(setTimeFromNow())
            } else {
                slickSlider.current.slickGoTo(0)
            }
        }
    }, [activeDayIndex]) */

    let sliderSettings = {
        /* draggable: false, */
        arrows: false,
        /* infinite: false, */
        speed: 200,
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const renderWeatherDetails = () => {
        if(windowDimensions.width > 991 ) {
            return(
                <div className="weatherTable">
                    <div className="userInfo">
                        <i className="fas fa-info-circle"></i>
                        Nutzen Sie die gedrückte Maustaste innerhalb der Tabelle, um die Zeit zu verschieben.
                    </div>
                    <div className="row no-gutters">
                        <div className="col-6 col-lg-4 col-xl-3">
                            <div className="tableHead">
                                <div className="firstItem"></div>
                                <div className="titleItem">Wetterzustand</div>
                                <div className="titleItem">Temperatur</div>
                                <div className="titleItem">Windgeschwindigkeit</div>
                                <div className="titleItem">Windrichtung</div>
                                <div className="titleItem">Niederschlag</div>
                                <div className="titleItem">Niederschlagswahrscheinlichkeit</div>
                                <div className="titleItem">Strahlung</div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-8 col-xl-9">
                            <Slider {...sliderSettings} ref={slickSlider}>
                                { weatherData[wogid].data[activeDayIndex].periods.map(function (period, index) {
                                    return(
                                        <div className="tableItem" key={period.hour}>
                                            <div className="firstItem time">{period.periodname}</div>
                                            <div className="tableContent weatherIcon">
                                                <img title={weatherInfo[period.wm]} alt={weatherInfo[period.wm]} src={require('../../images/icons/' + period.wm + '.svg')} />
                                            </div>
                                            <div className="tableContent temperature">{period.tt_C} °C</div>
                                            <div className="tableContent windSpeed">{windToKmh(period.ff_ms)} km/h</div>
                                            <div className="tableContent windSymbol"><div className={'descIcon dd-deg dd-deg--' + period.dd_deg}><i className="fas fa-location-arrow"></i></div></div>
                                            <div className="tableContent ">{period.rr_mm} mm</div>
                                            <div className="tableContent ">{period.pop} %</div>
                                            <div className="tableContent ">{period.rad_wm2} W/m²</div>
                                        </div>
                                    )
                                })}
                            </Slider>
                            <button className="icon-btn prevButton" onClick={() => slickPrev()}><i className="fas fa-chevron-left"></i></button>
                            <button className="icon-btn nextButton" onClick={() => slickNext()}><i className="fas fa-chevron-right"></i></button>
                        </div>
                    </div>

                    {renderFullDayDetails(weatherData[wogid].data[activeDayIndex])}
                </div>
            )
        }
    }

    if(weatherData[wogid] === undefined) {
        return(<LoadSpinner />);
    }
    else {
        return(
            <>
                <div className="weatherCards">
                    <div className="row">
                        {windowDimensions.width <= 991 ?
                            <div className="col-12">
                                <div className="mobileDescription">
                                    <div className="icon">
                                        <i className="fas fa-info-circle"></i>
                                    </div>
                                    <div className="info">
                                        Klicken Sie auf einen Tag für eine stündliche Auflösung.
                                    </div>
                                </div>
                            </div>
                        : null}
                        {renderWeatherCards()}
                    </div>
                </div>
                <div className="weatherDayDetails">
                    {renderWeatherDetails()}
                </div>
            </>
        )
    }
}

export default WeatherHourly;