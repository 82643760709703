// ActionType.js
// https://redux.js.org/basics/actions

// App
export const SET_APPDATA = 'SET_APPDATA';

//
export const FETCH_LOCATION_PENDING = 'FETCH_LOCATION_PENDING';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATION_FAILURE';
