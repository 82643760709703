import axios from 'axios'

export const apiRequest = async (request) => {

  if (!request.headers) {
    request.headers = {
      
    }
  }

  if (!request.withoutJson) {
    request.headers['Accept'] = 'application/json'
    request.headers['Content-Type'] = 'application/json'
  }

  try {
    const response = await axios.request(request)

    if (response.status >= 200 && response.status < 300) {
      return response
    }
    else {
      let error = new Error(response.statusText);
      (error).response = response
      throw error
    }

  } catch (error) {
    console.error('You have an error in your code or there are Network issues.', error)
  }
}