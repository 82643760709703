import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import '../App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'

// pages
import Root from './Root';
/* import Today from './Today'; */
import Day6Parts from './Day6Parts';
import Daily14 from './Daily14';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';

const history = createBrowserHistory()
history.listen(location => {
  if (window.location.hostname !== 'localhost') {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }
})

const App = () => {

  if (window.location.hostname !== 'localhost') {
    const trackingId = 'UA-23364675-7'
    ReactGA.initialize(trackingId)
    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname)
  }

  return (
    <Router>
      <div className="app">
          <Switch>
            <Route exact path='/' component={Root} />
            {/* <Route exact path='/heute' component={Today} /> */}
            <Route exact path='/6-tage' component={Day6Parts} />
            <Route exact path='/14-tage' component={Daily14} />

            <Route exact path='/impressum' component={Impressum} />
            <Route exact path='/datenschutz' component={Datenschutz} />
          </Switch>
        </div>
    </Router>
  );
}

export default App
