import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import LoadSpinner from '../LoadSpinner'

// services
import { weatherInfo, weatherSektoren } from '../../services'

const WeatherCardsWeekly = (props) => {

    const weatherData = props.weatherData
    const wogid = props.wogid

    const umrechnungWind = (ms) => {
        return (ms * 3.6).toFixed(0);
    }

    // windowsDimension
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width, innerHeight: height } = window
            setWindowDimensions({ width, height })
        }
    
        window.addEventListener('resize', handleResize)
    
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const renderWeatherCard = (day, index) => {
        return(
            <div className="weatherCard" key={index}>
                <div className="row no-gutters">
                    <div className="col-6 col-md-4 col-lg-12 d-flex jc-c ai-c">
                        <div className="date">
                            {index === 0 ? ( <div className="col-12"><div className="day-name">Heute,</div> <Moment format="DD.MM">{day.date}</Moment></div> ) : (<div className="col-12"><div className="day-name">{day.weekday},</div><Moment format="DD.MM">{day.date}</Moment></div>)}
                        </div>
                    </div>

                    <div className="col-6 col-md-4 col-lg-12 d-flex jc-c ai-c">
                        <div className="weatherIcon">
                            <img title={weatherInfo[day.wm]} alt={weatherInfo[day.wm]} src={require('../../images/icons/' + day.wm + '.svg')} />
                        </div>
                    </div>

                    <div className="col-12 col-md-4 col-lg-12 d-flex jc-c ai-c weatherInfo">
                        <div>
                            {weatherInfo[day.wm]}
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-md-3 col-lg-12 d-flex jc-c ai-c">
                        <div className="weatherDetailBox first">
                            <div className="descIcon"><i className="fad fa-thermometer-half"></i></div>
                            <div className="value">
                                <div>{day.tn_C} °C</div>
                                <div>{day.tx_C} °C</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-lg-12 d-flex jc-c ai-c">
                        <div className="weatherDetailBox">
                            <div className="descIcon"><i className="fad fa-dewpoint"></i></div>
                            <div className="value">
                                <div>{day.pop} %</div>
                                <div>{day.rr_mm} mm</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-lg-12 d-flex jc-c ai-c">
                        <div className="weatherDetailBox">
                            <div className="descIcon"><i className="fad fa-sun"></i></div>
                            <div className="value">
                                <div>{day.sd_h} h</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 col-lg-12 d-flex jc-c ai-c">
                        <div className="weatherDetailBox">
                            <div className="descIcon"><div className={'descIcon dd-deg dd-deg--' + day.dd_deg}><i className="fas fa-location-arrow"></i></div></div>
                            <div className="value">
                                <div>{umrechnungWind(day.ff_ms)} km/h</div>
                                <div>{weatherSektoren[day.dd_dir]}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if(weatherData[wogid] === undefined) {
        return(<LoadSpinner />);
    }
    else {
        return weatherData[wogid].data.map(function (day, index) {
            if(index === 7) {
                return (
                    <React.Fragment key={index}>
                        <div className="col-12 hr">
                            <div className="placeholder"></div>
                        </div>

                        <div className={windowDimensions.width > 991 ? 'col' : 'col-md-6'}>
                            {renderWeatherCard(day, index)}
                        </div>
                    </React.Fragment>
                )
            } else {
                return(
                    <div className={windowDimensions.width > 991 ? 'col' : 'col-md-6'} key={index}>
                        {renderWeatherCard(day, index)}
                    </div>
                )
            }
        })
    }
}

export default WeatherCardsWeekly;