const initState = {
    location: null,
    formattedAdress: null,
    autoGeolocation: null,
    wogid: null,
}

const appReducer = (state = initState, action) => {

    if(action.type === 'SET_APPDATA') {
        return { ...state, location: action.location, formattedAdress: action.formattedAdress, autoGeolocation: action.autoGeolocation, wogid:action.wogid}
    }

    return state;
}

export default appReducer;