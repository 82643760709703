import { apiRequest } from '../ApiRequest'
const Kaack_Token = 'hHkL9z0Y5a82blZZdWuiVafNdewaqP41'
//const Kaack_Token = 'password'

export const searchRequestPostCode = (plz) => {
    /* const url = new URL(`https://api.kt-core.de/weather/search/${plz}`) */
    const url = new URL(`https://api.kt-core.de/weather/v2/search/${plz}`)
    // const url = new URL(`http://localhost/weather/v2/search/${plz}`)
    return apiRequest({ method: 'get', url, headers: {'Authorization': 'Bearer ' + Kaack_Token}})
}

export const getWeather = (weatherPackage, wogid) => {
    /* const url = new URL(`https://api.kt-core.de/weather/${wogid}/${weatherPackage}`) */
    const url = new URL(`https://api.kt-core.de/weather/v2/${wogid}/${weatherPackage}`)
    // const url = new URL(`http://localhost/weather/v2/${wogid}/${weatherPackage}`)
    return apiRequest({ method: 'get', url, headers: {'Authorization': 'Bearer ' + Kaack_Token}})
}