import React from 'react'
import logo from '../images/logo-for-dark.svg'
import wetteronline from '../images/logo_wetteronline.svg'
import meteomatics from '../images/meteomatics_logo.svg'
import { NavLink } from 'react-router-dom'

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <footer className="app-footer">
            <div className="page-footer-top">
                <div className="totop" onClick={() => scrollToTop()}>
                    <i className="far fa-chevron-double-up"></i>
                </div>

                <div className="container">

                    <div className="logo"><a href="https://agrarwetter.app"><img src={logo} width="300" alt="Agrarwetter" /></a></div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="info">Auf einen Blick: Unser Agrarwetter für verschiedene Wetterprognosen. Mit Bodentemperatur, Niederschlagsmenge sowie Wind im zeitlichen Verlauf.</div>
                        </div>
                        <div className="col-lg-3">
                            
                        </div>
                        <div className="col-lg-3">
                            <div className="cooperation">
                                <div className="text">Eine Kooperation mit</div>
                                <div className="logo-extern">
                                    <a href='https://www.meteomatics.com/' target='_blank'>
                                        <img width="250" src={meteomatics} alt="Meteomatics AG" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="page-footer-bottom">
                    <div className="copyright">
                        <div>powered by <a href="https://www.kaack-terminhandel.de" target="_blank">Kaack Terminhandel GmbH</a></div>
                    </div>
                    <div className="policy-links">
                        <ul>
                            <li>
                                <NavLink to={'/datenschutz'} className="nav-link" activeClassName="active">Datenschutz</NavLink>
                            </li>
                            <li>
                                <NavLink to={'/impressum'} className="nav-link" activeClassName="active">Impressum</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </footer>
    );
}

export default Footer
