import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Geosuggest from 'react-geosuggest';
import Geocode from "react-geocode";
import axios from 'axios';
import { setAppData } from '../actions';
/* import { searchRequestPostCode } from '../utils/routes/Weather'; */

Geocode.setApiKey("AIzaSyDGQZa-v1DH1UFdFcAY8nSY8VFvMi4cTzs");
Geocode.setLanguage("de");

const API_Token = 'hHkL9z0Y5a82blZZdWuiVafNdewaqP41'

const WeatherSearchbar = () => {
    const dispatch = useDispatch()
    const app_formattedAdress = useSelector((state) => state.appReducer.formattedAdress)
    const app_wogid = useSelector((state) => state.appReducer.wogid)

    const _geoSuggest = useRef(null);

    const [location, setLocation] = useState(null)
    const [formattedAdress, setFormattedAdress] = useState(null)
    const [autoGeolocation, setAutoGeolocation] = useState(false)
    const [wogid, setWogid] = useState(null)

    useEffect(() => {

        if(app_wogid === null) {
            // setze aus Browser
            if(localStorage.getItem('wogid')) {
                dispatch(setAppData(null, localStorage.getItem('address'), false, localStorage.getItem('wogid')))
            }
            // wenn keine Daten vorhanden
            else {
                dispatch(setAppData(null, '10178 Berlin', false, "ChIJE7ee7N9RqEcRAA27xlt-cYk"))
                localStorage.setItem('address', '10178 Berlin');
                localStorage.setItem('wogid', 'ChIJE7ee7N9RqEcRAA27xlt-cYk');
            }
        }

        if(localStorage.getItem('wogid').length <= 5) {
            dispatch(setAppData(null, '10178 Berlin', false, "ChIJE7ee7N9RqEcRAA27xlt-cYk"))
            localStorage.setItem('address', '10178 Berlin');
            localStorage.setItem('wogid', 'ChIJE7ee7N9RqEcRAA27xlt-cYk');
        }

        if(wogid !== localStorage.getItem('wogid')) {
            dispatch(setAppData(null, localStorage.getItem('address'), false, localStorage.getItem('wogid')))
        }

    }, [app_wogid])

    const onSuggestSelect = (suggest) => {
        if(suggest !== undefined) {
            setLocation(suggest.location)
            setFormattedAdress(suggest.gmaps['formatted_address'])

            woApiRequest("get", suggest.location['lat'], suggest.location['lng'], suggest.gmaps['formatted_address'])
        }
    }

    const onAutoGeolocation = () => {
        //const google = window.google;

        navigator.geolocation.getCurrentPosition(position => {
            //const location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            setAutoGeolocation(true)

            withGeocode(position.coords.latitude, position.coords.longitude);
        })
    }

    const withGeocode = (lat, lng) => {
        // Get address from latidude & longitude.
        Geocode.fromLatLng(lat, lng).then(
            response => {

                /* console.log(response)

                console.log(response.results[0].address_components.filter(adress => adress.types[0] === 'locality')); */

                const address = response.results[0].formatted_address;

                setLocation({lat:lat, lng:lng})
                setFormattedAdress(address)

                _geoSuggest.current.update(address)
                
                woApiRequest("get", lat, lng, address);
            },
            error => {
                console.error(error);
            }
        )
    }

 /*    const searchPostCode = (plz) => {
        searchRequestPostCode(plz)
            .then((response) => {
                const data = response.data;

                if(data[data.length-1].match === 'yes') {

                    let wogid = data[0].gid
                    let address = data[0].zip + ' ' + data[0].locationName

                    setWogid(wogid)
                    setFormattedAdress(address)

                    localStorage.setItem('wogid', wogid)
                    localStorage.setItem('address', address)
    
                    // location, formattedAdress, autoGeolocation, wogid
                    dispatch(setAppData(location, address, autoGeolocation, wogid))
                }
            }).catch((error) => {
                console.log("Kaack-Widget Error: Please contact support@kaack-terminhandel.de")
            })
    } */

    // wrapper für wetteronline API
    const woApiRequest = (type, lat, lng, address) => {
        /* let url = 'https://agrarwetter.app/geosuche.php?longitude=' + lng + "&latitude=" + lat; */
        /* let url = 'https://api.kt-core.de/weather/search/' + lat + '/' + lng + '?api_token=' + API_Token */
        let url = 'https://api.kt-core.de/weather/v2/search/' + lat + '/' + lng + '?api_token=' + API_Token
        const headers = {
        
        };

        return axios[type](url,{
            headers
        })
        .then(_checkStatus)
        .then((response) => {
            const data = response.data;

            if(data[data.length-1].match === 'yes') {

                let wogid = data[0].gid

                setWogid(wogid)

                localStorage.setItem('address', address);
                localStorage.setItem('wogid', wogid);

                // location, formattedAdress, autoGeolocation, wogid
                dispatch(setAppData(location, address, autoGeolocation, wogid))
            }
        })
        // Fehlerbehandlung überarbeiten!
        .catch((response) => {
            console.log(response)
        })
        
    }

    const _checkStatus = (response) => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

    return (
        <div className="weatherSearchbar">
            <h1>Agrarwetter für {app_formattedAdress === null ? "Deutschland" : app_formattedAdress}</h1>
            <div className="searchFunctions">
                <button onClick={() => {onAutoGeolocation()}} title="Standort automatisch ermitteln"><i className="fas fa-map-marker-alt"></i></button>
                <Geosuggest 
                    placeholder="Postleitzahl, Ort"
                    onSuggestSelect={onSuggestSelect}
                    /* country={["DE", "AT", "CH"]} */
                    types={['(regions)']}
                    autoActivateFirstSuggest={'true'}
                    ref={_geoSuggest}
                />
            </div>
            
        </div>
    )

}

export default WeatherSearchbar
