import React from 'react'
import logo from '../images/logo.svg'
import WeatherSearchbarWithGoogle from './WeatherSearchbarWithGoogle'
import Navigation from './Navigation'
import Headroom from 'react-headroom'
import { useLocation } from 'react-router-dom'

const Header = ({ app }) => {
    
    const toggleMobilemenu = () => {
        app.current.classList.toggle('show-nav')
    }

    let location = useLocation()

    return (
        <header className="app-header">
            {/* <Headroom> */}
                <div className="header-bar">
                    <div className="container">
                        <div className="logo"><a href="https://agrarwetter.app"><img src={logo} width="300" alt="Agrarwetter" /></a></div>
                        <div className="flexStretch"></div>
                        {/* <WeatherSearchbarWithGoogle /> */}
                        <Navigation />

                        <div className="navbar-trigger" onClick={toggleMobilemenu}>
                            <div className="menu-name">Menü</div>
                            <div className="hamburger"><span></span></div>
                        </div>
                    </div>
                </div>
            {/* </Headroom> */}
            {location.pathname !== '/datenschutz' && location.pathname !== '/impressum' ?
                <div className="searchbar-cnt">
                    <div className="container">
                        <WeatherSearchbarWithGoogle />
                    </div>
                </div>
            : null}
        </header>
    );
}

export default Header
