import React, { useEffect, useState } from 'react'
import moment from 'moment'
import LoadSpinner from '../LoadSpinner'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
NoDataToDisplay(Highcharts)

const WeatherRainChart = (props) => {

    const weatherData = props.weatherData
    const wogid = props.wogid
    const weatherPackage = props.package

    /** Charting **/
    const [chart, setChart] = useState()

    const afterChartCreated = (chart) => {
        setChart(chart)
    }

    const mapPeriodsData = (type) => {

        if(weatherData[wogid] === undefined) {
            return null
        }
        else {
            let data = []
            weatherData[wogid].data.forEach(day => {
                const date = day.date;
                
                if(weatherPackage === 'daily14') {
                    let timestamp = moment(day.date).unix() * 1000

                    if(type === 'ff_ms') {
                        data.push({ x: timestamp, y: Number((day[type] * 3.6).toFixed(2)) })
                    }
                    else {
                        data.push({ x: timestamp, y: day[type] })
                    }
                } else {
                    day.periods.forEach(item => {
                        let hour = item['hour']
                        let timestamp = (moment(date).unix() + (hour*60*60)) * 1000;
    
                        if(type === 'ff_ms') {
                            data.push({ x: timestamp, y: Number((item[type] * 3.6).toFixed(2)) })
                        }
                        else {
                            data.push({ x: timestamp, y: item[type] })
                        }
                    })
                }
            })
            return data
        }
    }

    const mapDaysData = (type) => {
        if(weatherData[wogid] === undefined) {
            return null
        }
        else {
            let data = []
            weatherData[wogid].data.forEach(day => {
                const date = day.date;
                let timestamp = moment(date).unix() * 1000;

                data.push({ x: timestamp, y: day[type] })
            })
            return data
        }
    }

    Highcharts.setOptions({
        global : {
            useUTC : false
        },
        lang: {
            noData: "Chartdaten werden geladen.",
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            resetZoom: 'Reset'
        },
        style: {
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
        }
    })

    const tempChartOptions = {
        global: {
            useUTC: false,
        },
        chart: {
            type: 'spline',
        },
        title: {
            text: null
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 24 * 3600 * 1000, // one day
            gridLineWidth: 1,
            minorTickInterval: 6 * 3600 * 1000, // 6 hours
            minorGridLineWidth: 0,
            minorTickLength: 5,
            minorTickWidth: 1,
            /* opposite: true, */
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: false
                }
            },
            areaspline: {
                marker: {
                    enabled: false
                }
            }
        },
        yAxis: [{
                title: {
                text: 'Niederschlag [mm]'
                },
            },
            {
                title: {
                    text: 'Niederschl.wahr./Rel. Feuchte'
                },
                labels: {
                    format: '{value}%'
                },
                min: 0.0,
                max: 100.0,
                opposite: true
            }
        ],
        series: [
            {
                name: 'Relative Luftfeuchtigkeit',
                color: '#e8e8e8',
                type: 'areaspline',
                data: mapPeriodsData('rh'),
                tooltip: {
                    valueSuffix: "%",
                    valueType: "instant",
                },
                yAxis: 1
            },
            {
                name: 'Niederschlagswahrscheinlichkeit',
                color: '#b8e7f9',
                type: 'areaspline',
                data: mapPeriodsData('pop'),
                tooltip: {
                    valueSuffix: "%",
                    valueType: "period",
                },
                yAxis: 1
            },
            {
                name: 'Niederschlagsmenge',
                color: 'rgba(73, 98, 111, 0.8)',
                type: 'column',
                data: mapPeriodsData('rr_mm'),
                tooltip: {
                    valueSuffix: "mm",
                    valueType: "period",
                },
                yAxis: 0,
                groupPadding: 0.1
            }
        ],
        tooltip: {
            crosshairs: true,
            shared: true
        },
        legend: {
          enabled: true,
          style: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
          }
        },
    }

    const renderRainChart = () => {
        if(weatherData[wogid] === undefined) {
            return(<LoadSpinner />);
        } else {
            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={tempChartOptions}
                    callback={afterChartCreated}
                />
            )
        }
    }

    return (
        renderRainChart()
    );
}

export default WeatherRainChart;