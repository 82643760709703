import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import LoadSpinner from '../LoadSpinner'

// services
import { weatherInfo, weatherSektoren } from '../../services'

const WeatherCardsWeekly = (props) => {

    const weatherData = props.weatherData
    const wogid = props.wogid

    const umrechnungWind = (ms) => {
        return (ms * 3.6).toFixed(0);
    }

    if(weatherData[wogid] === undefined) {
        return(<LoadSpinner />);
    }
    
    else {
        return weatherData[wogid].data.map(function (tag, index) {
            return(
                <div className="col-md-12 col-lg-2" key={index}>
                    <div className="weatherCard">
                        <div className="row no-gutters">
                            <div className="col-6 col-md-4 col-lg-12 d-flex jc-c ai-c">
                                <div className="date">
                                    {index === 0 ? ( <div className="col-12"><div className="day-name">Heute,</div> <Moment format="DD.MM">{tag.date}</Moment></div> ) : (<div className="col-12"><div className="day-name">{tag.weekday},</div><Moment format="DD.MM">{tag.date}</Moment></div>)}
                                </div>
                            </div>

                            <div className="col-6 col-md-4 col-lg-12 d-flex jc-c ai-c">
                                <div className="weatherIcon">
                                    <img title={weatherInfo[tag.wm]} alt={weatherInfo[tag.wm]} src={require('../../images/icons/' + tag.wm + '.svg')} />
                                </div>
                            </div>

                            <div className="col-12 col-md-4 col-lg-12 d-flex jc-c ai-c weatherInfo">
                                <div>
                                    {weatherInfo[tag.wm]}
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-md-3 col-lg-12 d-flex jc-c ai-c">
                                <div className="weatherDetailBox first">
                                    <div className="descIcon"><i className="fad fa-thermometer-half"></i></div>
                                    <div className="value">
                                        <div>{tag.tn_C} °C</div>
                                        <div>{tag.tx_C} °C</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-lg-12 d-flex jc-c ai-c">
                                <div className="weatherDetailBox">
                                    <div className="descIcon"><i className="fad fa-dewpoint"></i></div>
                                    <div className="value">
                                        <div>{tag.pop} %</div>
                                        <div>{tag.rr_mm} mm</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-lg-12 d-flex jc-c ai-c">
                                <div className="weatherDetailBox">
                                    <div className="descIcon"><i className="fad fa-sun"></i></div>
                                    <div className="value">
                                        <div>{tag.sd_h} h</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-6 col-md-3 col-lg-12 d-flex jc-c ai-c">
                                <div className="weatherDetailBox">
                                    <div className="descIcon"><div className={'descIcon dd-deg dd-deg--' + tag.dd_deg}><i className="fas fa-location-arrow"></i></div></div>
                                    <div className="value">
                                        <div>{umrechnungWind(tag.ff_ms)} km/h</div>
                                        <div>{weatherSektoren[tag.dd_dir]}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )
        })
    }
}

export default WeatherCardsWeekly;