export const weatherInfo = {
    bd____ : 'bedeckt',
    bdg1__ : 'bedeckt, vereinzelt Schauer und Gewitter',
    bdg2__ : 'bedeckt, Schauer und Gewitter',
    bdg3__ : 'bedeckt, kräftige Schauer und Gewitter',
    bdgr1_ : 'bedeckt, gefrierender Sprühregen',
    bdgr2_ : 'bedeckt, gefrierender Regen',
    bdr1__ : 'bedeckt, etwas Regen',
    bdr2__ : 'bedeckt, Regen',
    bdr3__ : 'bedeckt, ergiebiger Regen',
    bds1__ : 'bedeckt, vereinzelt Schauer',
    bds2__ : 'bedeckt, Schauer',
    bds3__ : 'bedeckt, kräftige Schauer',
    bdsg__ : 'bedeckt, Schneegewitter',
    bdsn1_ : 'bedeckt, leichter Schneefall',
    bdsn2_ : 'bedeckt, Schneefall',
    bdsn3_ : 'bedeckt, ergiebiger Schneefall',
    bdsns1 : 'bedeckt, vereinzelt Schneeschauer',
    bdsns2 : 'bedeckt, Schneeschauer',
    bdsns3 : 'bedeckt, kräftige Schneeschauer',
    bdsr1_ : 'bedeckt, leichter Schneeregen',
    bdsr2_ : 'bedeckt, Schneeregen',
    bdsr3_ : 'bedeckt, ergiebiger Schneeregen',
    bdsrs1 : 'bedeckt, vereinzelt Schneeregenschauer',
    bdsrs2 : 'bedeckt, Schneeregenschauer',
    bdsrs3 : 'bedeckt, kräftige Schneeregenschauer',
    bw____ : 'bewölkt',
    bwg1__ : 'bewölkt, vereinzelt Schauer und Gewitter',
    bwg2__ : 'bewölkt, Schauer und Gewitter',
    bwg3__ : 'bewölkt, kräftige Schauer und Gewitter',
    bwgr1_ : 'bewölkt, gefrierender Sprühregen',
    bwgr2_ : 'bewölkt, gefrierender Regen',
    bwr1__ : 'bewölkt, etwas Regen',
    bwr2__ : 'bewölkt, Regen',
    bwr3__ : 'bewölkt, ergiebiger Regen',
    bws1__ : 'bewölkt, vereinzelt Schauer',
    bws2__ : 'bewölkt, Schauer',
    bws3__ : 'bewölkt, kräftige Schauer',
    bwsg__ : 'bewölkt, Schneegewitter',
    bwsn1_ : 'bewölkt, leichter Schneefall',
    bwsn2_ : 'bewölkt, Schneefall',
    bwsn3_ : 'bewölkt, ergiebiger Schneefall',
    bwsns1 : 'bewölkt, vereinzelt Schneeschauer',
    bwsns2 : 'bewölkt, Schneeschauer',
    bwsns3 : 'bewölkt, kräftige Schneeschauer',
    bwsr1_ : 'bewölkt, leichter Schneeregen',
    bwsr2_ : 'bewölkt, Schneeregen',
    bwsr3_ : 'bewölkt, ergiebiger Schneeregen',
    bwsrs1 : 'bewölkt, vereinzelt Schneeregenschauer',
    bwsrs2 : 'bewölkt, Schneeregenschauer',
    bwsrs3 : 'bewölkt, kräftige Schneeregenschauer',
    mb____ : 'unterschiedlich bewölkt',
    mbg1__ : 'unterschiedlich bewölkt, vereinzelt Schauer und Gewitter',
    mbg2__ : 'unterschiedlich bewölkt, Schauer und Gewitter',
    mbg3__ : 'unterschiedlich bewölkt, kräftige Schauer und Gewitter',
    mbgr1_ : 'unterschiedlich bewölkt, gefrierender Sprühregen',
    mbgr2_ : 'unterschiedlich bewölkt, gefrierender Regen',
    mbr1__ : 'unterschiedlich bewölkt, etwas Regen',
    mbr2__ : 'unterschiedlich bewölkt, Regen',
    mbr3__ : 'unterschiedlich bewölkt, ergiebiger Regen',
    mbs1__ : 'unterschiedlich bewölkt, vereinzelt Schauer',
    mbs2__ : 'unterschiedlich bewölkt, Schauer',
    mbs3__ : 'unterschiedlich bewölkt, kräftige Schauer',
    mbsg__ : 'unterschiedlich bewölkt, Schneegewitter',
    mbsn1_ : 'unterschiedlich bewölkt, leichter Schneefall',
    mbsn2_ : 'unterschiedlich bewölkt, Schneefall',
    mbsn3_ : 'unterschiedlich bewölkt, ergiebiger Schneefall',
    mbsns1 : 'unterschiedlich bewölkt, vereinzelt Schneeschauer',
    mbsns2 : 'unterschiedlich bewölkt, Schneeschauer',
    mbsns3 : 'unterschiedlich bewölkt, kräftige Schneeschauer',
    mbsr1_ : 'unterschiedlich bewölkt, leichter Schneeregen',
    mbsr2_ : 'unterschiedlich bewölkt, Schneeregen',
    mbsr3_ : 'unterschiedlich bewölkt, ergiebiger Schneeregen',
    mbsrs1 : 'unterschiedlich bewölkt, vereinzelt Schneeregenschauer',
    mbsrs2 : 'unterschiedlich bewölkt, Schneeregenschauer',
    mbsrs3 : 'unterschiedlich bewölkt, kräftige Schneeregenschauer',
    md____ : 'bedeckt',
    mdg1__ : 'bedeckt, vereinzelt Schauer und Gewitter',
    mdg2__ : 'bedeckt, Schauer und Gewitter',
    mdg3__ : 'bedeckt, kräftige Schauer und Gewitter',
    mdgr1_ : 'bedeckt, gefrierender Sprühregen',
    mdgr2_ : 'bedeckt, gefrierender Regen',
    mdr1__ : 'bedeckt, etwas Regen',
    mdr2__ : 'bedeckt, Regen',
    mdr3__ : 'bedeckt, ergiebiger Regen',
    mds1__ : 'bedeckt, vereinzelt Schauer',
    mds2__ : 'bedeckt, Schauer',
    mds3__ : 'bedeckt, kräftige Schauer',
    mdsg__ : 'bedeckt, Schneegewitter',
    mdsn1_ : 'bedeckt, leichter Schneefall',
    mdsn2_ : 'bedeckt, Schneefall',
    mdsn3_ : 'bedeckt, ergiebiger Schneefall',
    mdsns1 : 'bedeckt, vereinzelt Schneeschauer',
    mdsns2 : 'bedeckt, Schneeschauer',
    mdsns3 : 'bedeckt, kräftige Schneeschauer',
    mdsr1_ : 'bedeckt, leichter Schneeregen',
    mdsr2_ : 'bedeckt, Schneeregen',
    mdsr3_ : 'bedeckt, ergiebiger Schneeregen',
    mdsrs1 : 'bedeckt, vereinzelt Schneeregenschauer',
    mdsrs2 : 'bedeckt, Schneeregenschauer',
    mdsrs3 : 'bedeckt, kräftige Schneeregenschauer',
    mm____ : 'leicht bewölkt',
    mo____ : 'klar',
    ms____ : 'meist sonnig',
    mw____ : 'bewölkt',
    mwg1__ : 'bewölkt, vereinzelt Schauer und Gewitter',
    mwg2__ : 'bewölkt, Schauer und Gewitter',
    mwg3__ : 'bewölkt, kräftige Schauer und Gewitter',
    mwgr1_ : 'bewölkt, gefrierender Sprühregen',
    mwgr2_ : 'bewölkt, gefrierender Regen',
    mwr1__ : 'bewölkt, etwas Regen',
    mwr2__ : 'bewölkt, Regen',
    mwr3__ : 'bewölkt, ergiebiger Regen',
    mws1__ : 'bewölkt, vereinzelt Schauer',
    mws2__ : 'bewölkt, Schauer',
    mws3__ : 'bewölkt, kräftige Schauer',
    mwsg__ : 'bewölkt, Schneegewitter',
    mwsn1_ : 'bewölkt, leichter Schneefall',
    mwsn2_ : 'bewölkt, Schneefall',
    mwsn3_ : 'bewölkt, ergiebiger Schneefall',
    mwsns1 : 'bewölkt, vereinzelt Schneeschauer',
    mwsns2 : 'bewölkt, Schneeschauer',
    mwsns3 : 'bewölkt, kräftige Schneeschauer',
    mwsr1_ : 'bewölkt, leichter Schneeregen',
    mwsr2_ : 'bewölkt, Schneeregen',
    mwsr3_ : 'bewölkt, ergiebiger Schneeregen',
    mwsrs1 : 'bewölkt, vereinzelt Schneeregenschauer',
    mwsrs2 : 'bewölkt, Schneeregenschauer',
    mwsrs3 : 'bewölkt, kräftige Schneeregenschauer',
    nb____ : 'Nebel',
    nm____ : 'teils Nebel, teils klar',
    ns____ : 'teils Nebel, teils Sonne',
    so____ : 'sonnig',
    trans : '-----',
    wb____ : 'unterschiedlich bewölkt',
    wbg1__ : 'unterschiedlich bewölkt, vereinzelt Schauer und Gewitter',
    wbg2__ : 'unterschiedlich bewölkt, Schauer und Gewitter',
    wbg3__ : 'unterschiedlich bewölkt, kräftige Schauer und Gewitter',
    wbgr1_ : 'unterschiedlich bewölkt, gefrierender Sprühregen',
    wbgr2_ : 'unterschiedlich bewölkt, gefrierender Regen',
    wbr1__ : 'unterschiedlich bewölkt, etwas Regen',
    wbr2__ : 'unterschiedlich bewölkt, Regen',
    wbr3__ : 'unterschiedlich bewölkt, ergiebiger Regen',
    wbs1__ : 'unterschiedlich bewölkt, vereinzelt Schauer',
    wbs2__ : 'unterschiedlich bewölkt, Schauer',
    wbs3__ : 'unterschiedlich bewölkt, kräftige Schauer',
    wbsg__ : 'unterschiedlich bewölkt, Schneegewitter',
    wbsn1_ : 'unterschiedlich bewölkt, leichter Schneefall',
    wbsn2_ : 'unterschiedlich bewölkt, Schneefall',
    wbsn3_ : 'unterschiedlich bewölkt, ergiebiger Schneefall',
    wbsns1 : 'unterschiedlich bewölkt, vereinzelt Schneeschauer',
    wbsns2 : 'unterschiedlich bewölkt, Schneeschauer',
    wbsns3 : 'unterschiedlich bewölkt, kräftige Schneeschauer',
    wbsr1_ : 'unterschiedlich bewölkt, leichter Schneeregen',
    wbsr2_ : 'unterschiedlich bewölkt, Schneeregen',
    wbsr3_ : 'unterschiedlich bewölkt, ergiebiger Schneeregen',
    wbsrs1 : 'unterschiedlich bewölkt, vereinzelt Schneeregenschauer',
    wbsrs2 : 'unterschiedlich bewölkt, Schneeregenschauer',
    wbsrs3 : 'unterschiedlich bewölkt, kräftige Schneeregenschauer',
};

export const weatherSektoren = {
    n : 'Norden',
    nw : 'Nordwesten',
    w : 'Westen',
    sw : 'Südwesten',
    s : 'Süden',
    so : 'Südosten',
    o : 'Osten',
    no : 'Nordosten',
    0 : 'umlaufend',
}