import React from 'react';
import { NavLink } from 'react-router-dom'

const Navigation = () => {
    return (
        <div className="menuBar">
            <ul className="mainmenu">
                <li>
                    <NavLink exact to={'/'} className="nav-link" activeClassName="active">Startseite</NavLink>
                </li>
                {/* <li>
                    <NavLink to={'/heute'} className="nav-link" activeClassName="active">Heute (Testpage)</NavLink>
                </li> */}
                <li>
                    <NavLink exact to={'/6-tage'} className="nav-link" activeClassName="active">6-Tage-Wetter</NavLink>
                </li>
                <li>
                    <NavLink exact to={'/14-tage'} className="nav-link" activeClassName="active">14-Tage-Trend</NavLink>
                </li>
            </ul>
        </div>
    );
}

export default Navigation;
