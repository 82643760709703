
import axios from 'axios';
/* import md5 from 'md5';
import base64 from 'base-64'; */

export default class HelperMethods {

  // wrapper für wetteronline API
  woApiRequest = (type, query) => {
    //let url = 'https://api.wetteronline.de/geo?';
    let url = 'https://agrarwetter.app/wrapper.php?q='

/*     const uid = 'a2FhY2s=';
    const secret = 'ajB4a2lpdG5pNWJ0dDNv'; */

    //let queryString = '';

    //const today = new Date();
    //const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getHours();
    
/*     const queryParameters = [
      date,
      query,
      uid,
    ]; */

/*     queryParameters.map(function(item, i) {
      queryString += item + '|';
    });

    queryString += secret; */
    //let checksum = base64.encode(md5(queryString));

    //url = url + 'date=' + date + '&name=' + query + '&uid=' + uid + '&checksum=' + checksum;
    //let q = 'https://api.wetteronline.de/geo?date=' + date + '&name=' + query + '&uid=' + uid + '&checksum=' + checksum;
    url = url + query;

    const headers = {
      
    };

    //console.log(url);

    return axios[type](url,{
      headers
    })
    .then(this._checkStatus)
    .then((response) => {
      return response
    })
    
  }

  _checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  };

}