import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from '../pages/App';

const ReduxRouter = () => (
    <Router>
      <Route path="/:filter?" component={App} />
    </Router>
)

export default ReduxRouter;