// index.js
import * as actionType from './ActionType';
import HelperMethods from "../components/HelperMethods";

const DataHelper = new HelperMethods();

// Suchanfrage
export function fetchLocation(query) {

    return function (dispatch) {

    dispatch({
        type: actionType.FETCH_LOCATION_PENDING,
    })

    DataHelper.woApiRequest("get", query)
        .then((response) => dispatch({
            type: actionType.FETCH_LOCATION_SUCCESS,
            data: response.data,
        }))
        .catch((response) => dispatch({
            type: actionType.FETCH_LOCATION_FAILURE,
            data: response
        }))
    }
}

// wetteronline gid
export function setAppData(location, formattedAdress, autoGeolocation, wogid) {
    return function (dispatch) {

        dispatch({
            type: actionType.SET_APPDATA,
            location: location,
            formattedAdress: formattedAdress,
            autoGeolocation: autoGeolocation,
            wogid: wogid
        })
    }
}