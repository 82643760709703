import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useSelector } from 'react-redux'
import { getWeather } from '../utils/routes/Weather';
import WeatherCardsWeekly from '../containers/weather/WeatherCardsWeekly'
import WeatherWindChart from '../containers/weather/WeatherWindChart'
import WeatherTempChart from '../containers/weather/WeatherTempChart'
import WeatherRainChart from '../containers/weather/WeatherRainChart'

const Trend = () => {

    const [weatherData, setWeatherData] = useState({})
    const wogid = useSelector((state) => state.appReducer.wogid)
    const weatherPackage = 'day6parts'

    useEffect(() => {
        refreshWeather(wogid);
    }, [])

    useEffect(() => {
        refreshWeather(wogid);
    }, [wogid])

    const refreshWeather = (wogid) => {
        getWeather(weatherPackage, wogid)
        .then((response) => {
            const data = response.data;
            setWeatherData(data)
        })
        .catch((error) => {
            console.log("Error: Please contact support@kaack-terminhandel.de")
        })
    }

    return (
        <Layout>
            <div className="container">
                <div className="day6parts">

                    <div className="title">
                        <h2>6-Tage-Wetterprognose</h2>
                        <p>6-stündliche Auflösung</p>
                    </div>

                    <div className="row">
                        <WeatherCardsWeekly weatherData={weatherData} wogid={wogid} package={weatherPackage}/>
                    </div>

                    <div className="title">
                        <h2>Temperatur</h2>
                        <p>6-stündliche Auflösung</p>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="weatherChart">
                                <WeatherTempChart weatherData={weatherData} wogid={wogid} package={weatherPackage} />
                            </div>
                        </div>
                    </div>

                    <div className="title">
                        <h2>Niederschlag [mm]</h2>
                        <p>6-stündliche Auflösung</p>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="weatherChart">
                                <WeatherRainChart weatherData={weatherData} wogid={wogid} package={weatherPackage} />
                            </div>
                        </div>
                    </div>

                    <div className="title">
                        <h2>Wind</h2>
                        <p>6-stündliche Auflösung</p>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="weatherChart">
                                <WeatherWindChart weatherData={weatherData} wogid={wogid} package={weatherPackage} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default Trend;