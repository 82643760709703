import React, { useEffect, useState } from 'react'
import moment from 'moment'
import LoadSpinner from '../LoadSpinner'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import arrowIcon from '../../images/arrow.svg'
NoDataToDisplay(Highcharts)

const WeatherWindChart = (props) => {

    // windowsDimension
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        const handleResize = () => {
            const { innerWidth: width, innerHeight: height } = window
            setWindowDimensions({ width, height })
        }

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const weatherData = props.weatherData
    const wogid = props.wogid
    const weatherPackage = props.package

    /** Charting **/
    const [chart, setChart] = useState()

    const afterChartCreated = (chart) => {
        setChart(chart)
    }

    const mapPeriodsData = (type) => {

        if(weatherData[wogid] === undefined) {
            return null
        }
        else {
            let data = []
            weatherData[wogid].data.forEach(day => {
                const date = day.date;
                
                if(weatherPackage === 'daily14') {
                    let timestamp = moment(day.date).unix() * 1000

                    if(type === 'ff_ms') {
                        data.push({ x: timestamp, y: Number((day[type] * 3.6).toFixed(2)) })
                    }
                    else {
                        data.push({ x: timestamp, y: day[type] })
                    }
                } else {
                    day.periods.forEach(item => {
                        let hour = item['hour']
                        let timestamp = (moment(date).unix() + (hour*60*60)) * 1000;
    
                        if(type === 'ff_ms') {
                            data.push({ x: timestamp, y: Number((item[type] * 3.6).toFixed(2)) })
                        }
                        else {
                            data.push({ x: timestamp, y: item[type] })
                        }
                    })
                }
            })
            return data
        }
    }

    const returnWindDir = () => {
        if(weatherData[wogid] === undefined) {
            return null
        }
        else {
            let data = []
            weatherData[wogid].data.forEach(day => {
                day.periods.forEach(item => {
                    data.push(item['dd_deg'])
                })
            })
            return data
        }
    }

    Highcharts.setOptions({
        global : {
            useUTC : false
        },
        lang: {
            noData: "Chartdaten werden geladen.",
            months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            resetZoom: 'Reset'
        },
        style: {
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
        }
    })

    const drawWindArrows = (chart) => {

        if(windowDimensions.width >= 768 && (weatherPackage === 'day6parts')) {
            const windDir = returnWindDir()

            chart.series[0].data.forEach((point, index) => {

                let windDirFinal = windDir[index] + 135;

                // put image into chart
                var outerGroup = chart.renderer.g()
                .attr({
                    translateX: point.plotX + chart.plotLeft - 11,
                    translateY: 5,
                    zIndex: 5
                })
                .add();

                // rotate image
                var innerGroup = chart.renderer.g()
                .attr({
                    x: 11,
                    y: 11,
                    rotation: windDirFinal
                })
                .add(outerGroup);

                // get wind arrow out of sprite
                chart.renderer.image(
                    arrowIcon,
                    0,
                    0,
                    20,
                    20,
                )
                .add(innerGroup);
                
            })
        }
    }

    const setMargin = () => {
        if(windowDimensions.width >= 768) {
            return 60
        } else {
            return 10
        }
    }

    const windChartOptions = {
        global: {
            useUTC: false,
        },
        chart: {
            type: 'spline',
            marginTop: setMargin(), // für die Symbole
            events: {
                'redraw':
                    function (event) {
                        drawWindArrows(this);
                    }
            }
        },
        title: {
            text: null
        },
        xAxis: [
            {
                type: 'datetime',
                tickInterval: 24 * 3600 * 1000, // one day
                gridLineWidth: 1,
                minorTickInterval: 6 * 3600 * 1000, // 6 hours
                minorGridLineWidth: 0,
                minorTickLength: 5,
                minorTickWidth: 1,
            },
            {
                type: 'datetime',
                tickInterval: 24 * 3600 * 1000, // one day
                gridLineWidth: 1,
                minorTickInterval: 6 * 3600 * 1000, // 6 hours
                minorGridLineWidth: 0,
                minorTickLength: 5,
                minorTickWidth: 1,
                opposite: true,
                linkedTo: 0
            },
        ],
        yAxis: {
            title: {
            text: 'Windgeschwindigkeit [km/h]'
            },
            plotLines: [{
                color: '#27a22d',
                width: 2,
                value: 18.5,
                zIndex: 5
            }],
            plotBands: [{
                from: 1.85,
                to: 9,
                color:'rgba(156, 156, 156, 0.1)',
                label: {
                    text: 'Geringer Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 9,
                to: 19,
                color: '#FFFFFF',
                label: {
                    text: 'Leichter Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 19,
                to: 28,
                color: 'rgba(156, 156, 156, 0.1)',
                label: {
                    text: 'Schwacher Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 28,
                to: 37,
                color: '#FFFFFF',
                label: {
                    text: 'Mäßiger Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },

            {
                from: 37,
                to: 46,
                color: 'rgba(156, 156, 156, 0.1)',
                label: {
                    text: 'Frischer Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 46,
                to: 56,
                color: '#FFFFFF',
                label: {
                    text: 'Starker Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },

            {
                from: 56,
                to: 65,
                color:'rgba(156, 156, 156, 0.1)',
                label: {
                    text: 'Starker bis stürmischer Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 65,
                to: 74,
                color: '#FFFFFF',
                label: {
                    text: 'Stürmischer Wind',
                    style: {
                        color: '#606060'
                    }
                }
            },

            {
                from: 74,
                to: 83,
                color:'rgba(156, 156, 156, 0.1)',
                label: {
                    text: 'Sturm',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 83,
                to: 102,
                color: '#FFFFFF',
                label: {
                    text: 'Schwerer Sturm',
                    style: {
                        color: '#606060'
                    }
                }
            },

            {
                from: 102,
                to: 117,
                color:'rgba(156, 156, 156, 0.1)',
                label: {
                    text: 'Orkanartiger Sturm',
                    style: {
                        color: '#606060'
                    }
                }
            },
            {
                from: 117,
                to: 194,
                color: '#FFFFFF',
                label: {
                    text: 'Orkan',
                    style: {
                        color: '#606060'
                    }
                }
            }
        ]

        },
        credits: {
            enabled: false
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: false
                }
            }
        },
        series: [{
                name: 'Windgeschwindingkeit',
                color: '#31535B',
                data: mapPeriodsData('ff_ms'),
                tooltip: {
                    valueSuffix: " km/h",
                    valueType: "instant",
                },
                lineWidth: 3
            },
            {
                name: 'Böen',
                color: '#adadad',
                data: mapPeriodsData('fx_kmh'),
                tooltip: {
                valueSuffix: " km/h",
                valueType: "instant",
                },
            },
            {
                name: 'Grenzwert Pflanzenschutzausbr. (18,5 km/h)',
                color: '#79AC37'
            }
        ],
        tooltip: {
            crosshairs: true,
            shared: true
        },
        legend: {
          enabled: true,
          style: {
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
          }
        },
    }

    const renderWindChart = () => {
        if(weatherData[wogid] === undefined) {
            return(<LoadSpinner />);
        } else {
            return (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={windChartOptions}
                    callback={afterChartCreated}
                />
            )
        }
    }

    return (
        renderWindChart()
    );
}

export default WeatherWindChart;