import React, { useLayoutEffect, useState, useEffect } from 'react';
import Layout from '../components/Layout'
import { getWeather } from '../utils/routes/Weather';
import { useSelector } from 'react-redux'
import WeatherHourly from '../containers/weather/WeatherHourly'
import WeatherTempChart from '../containers/weather/WeatherTempChart'
import WeatherRainChart from '../containers/weather/WeatherRainChart'
import WeatherWindChart from '../containers/weather/WeatherWindChart'

const Root = () => {

    const [weatherData, setWeatherData] = useState({})
    const wogid = useSelector((state) => state.appReducer.wogid)
    const weatherPackage = 'hourly72'

    useEffect(() => {
        refreshWeather(wogid);
    }, [])

    useEffect(() => {
        refreshWeather(wogid);
    }, [wogid])

    const refreshWeather = (wogid) => {
        getWeather(weatherPackage, wogid)
        .then((response) => {
            const data = response.data;
            setWeatherData(data)
        })
        .catch((error) => {
            console.log("Error: Please contact support@kaack-terminhandel.de")
        })
    }

    return (
        <Layout>
            <div className="container">
                <div className="hourly72">

                    <div className="extended-title">
                        <div className="title">
                            <h2>3-Tage-Wetterprognose</h2>
                            <p>(stündliche, detaillierte Auflösung)</p>
                        </div>

                        {/* <div className="significance">
                            <div className="desc-text">Genauigkeit: (In jede Box packen !!)</div>
                            <div className="green-msg">
                                ziemlich sicher
                            </div>
                        </div> */}
                    </div>

                    <WeatherHourly weatherData={weatherData} wogid={wogid} />
                    
                    <div className="title">
                        <h2>Temperatur</h2>
                        <p>(stündliche, detaillierte Auflösung)</p>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="weatherChart">
                                <WeatherTempChart weatherData={weatherData} wogid={wogid} package={weatherPackage} />
                            </div>
                        </div>
                    </div>

                    <div className="title">
                        <h2>Niederschlag [mm]</h2>
                        <p>(stündliche, detaillierte Auflösung)</p>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="weatherChart">
                                <WeatherRainChart weatherData={weatherData} wogid={wogid} package={weatherPackage} />
                            </div>
                        </div>
                    </div>

                    <div className="title">
                        <h2>Wind</h2>
                        <p>(stündliche, detaillierte Auflösung)</p>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="weatherChart">
                                <WeatherWindChart weatherData={weatherData} wogid={wogid} package={weatherPackage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default Root;